
import React, { useState, useEffect } from 'react';
import checkLogin from "../function/checkLogin.js"
import getDay from "../function/getDay"
import getUrl from '../function/getUrl'
import logo from "../icon/good2swim-logo.png";
import checkLineLogin from "../function/checkLineLogin"
import { Link, useParams } from "react-router-dom";
import liff from '@line/liff';


let liffId = process.env.REACT_APP_liffId;
let env = process.env.lindID;
const url = getUrl()
const Login = () => {

    let lineID=sessionStorage.getItem('lineID')
  
    const [msg, setMsg] = useState(null)
    // sessionStorage.clear();
    const [phone, setPhone] = useState('');
    const [password, setPassword] = useState('');
    const [error, setError] = useState('')

    function haneleAccountChange(e) {
        setPhone(e.target.value)

    };

    function handlePassword(e) {
        setPassword(e.target.value)

    };


    function good2swimLogin(e) {
        liff.init({ liffId: liffId }).then(async() => {
            if (liff.isInClient()) {
       
                
                // alert('first')
                    await liff.getProfile().then(async profile => {
                            const lineID = profile.userId //處理 LineID
                            //  alert(lineID)
                           await  fetch(`${url}/loginWithLineID?phone=${phone}&password=${password}&lineID=${lineID}`, {
                                mode: 'cors',
                            }).then(res => {
                                return res.json();
                            }).then(json => {
                    
                                //  alert(json)
                                if (json.length > 0) {
                                    sessionStorage.setItem('studentData', JSON.stringify(json));
                    
                                    window.location.href = "/pickupStudent/home"
                    
                                } else {
                                    setMsg('帳號或密碼錯誤')
                                }
                    
                                return 
                            })
                            
                        })
                        .catch((err) => {
                            console.log('error', err);
                        });
                        return 
                    // let profile = liff.getProfile().then(profile=>profile)
                    // let msg = `hello ${profile.displayName} ${profile.userId}`
                    // alert(msg)
                    // // console.log(liff.getProfile())
                    // //處理登入作業 -》
                    // /*
                    // （1) 與server 確認本 LineUserID是否存在 , 如果已連結, 則直接登入系統
                    // (2) 如果LineUserID不存在，保留LineUserID ，加入登入畫面送出 LineUserID 一併儲存
                    // */
                    // return profile
                
            } else {
                console.log('here')
    
                 window.location.href="/login"
    
            }
        });
    
    }



    return (
        <>
        {/* <small className="text-danger text-center">Line  </small> */}
        
            <div className="container  " style={{maxWidth:"520px"}}>
                <div role="alert" color="warning">
                    <div style={{ textAlign: 'center' }}>
                        <img src={logo} alt="logo" style={{ weight: "120px", height: "120px" }} />
                    </div>




                    <div className="card-body border-0">

                        <form>
                            <div className="card mb-2 ">
                                <div className="card-body alert-info  border-1 p-2 text-center">歡迎回來<br />請輸入您的手機與密碼</div>
                                <div className="card-body border-1" style={{ width: "100%" }}>

                                    <div className="formGroup">
                                        <label>手機
                            <input type="text" name="phone" id="phone" className="form-control" style={{ width: "100%" }} value={phone} onChange={haneleAccountChange} />
                                            <small id="emailHelp" className="form-text text-muted">您的手機即為帳號</small>
                                        </label>
                                    </div>
                                    <div className="formGroup">
                                        <label >密碼
                            <input type="password" name="password" value={password} style={{ width: "100%" }} id="examplePassword" className="form-control" onChange={handlePassword} />
                            <small id="emailHelp" className="form-text text-muted">預設為出生日期 月日共4碼</small>
                                        </label>
                                    </div>

                                    <br />
                                    <div className="formGroup ">
                                        <div className="btn btn-info"  style={{ width: "100%" }} onClick={good2swimLogin} > 登入</div>

                                    </div>
                                    {msg}
                                </div>
                            </div>


                        </form>
                    </div>
                </div>

                <div className="formGroup text-center"><h5>{error}</h5></div>
                <small className="text-muted text-center">Line  </small>
            </div>

        </>
    );
}




export default Login;