
import React, { useState, useEffect } from 'react';
import checkLogin from "../function/checkLogin.js"
import getDay from "../function/getDay"
import getUrl from '../function/getUrl'
import logo from "../icon/good2swim-logo.png";
import birthdayToAge from "../function/birthdayToAge.js"
import checkStudent from '../function/checkStudent'
import pencilIcon from "../icon/cancel_red.svg";
import makeClassRecordCancel from "../function/makeClassRecordCancel.js"
import person from "../icon/person.svg"

const url = getUrl()
const StudentCard = () => {
    //確認系統storage中有指定學員資料
    checkStudent()
    const today = getDay(0, "-")
    const cancelDay = getDay(3, "-")

    const student = JSON.parse(sessionStorage.getItem("student"))
    const [studentName, setStudentName] = useState(student.name)
 
    const [classBalance, setClassBalance] = useState(0)
    const [classBalanceELP, setClassBalanceELP] = useState(0)
    const [classTotal, setClassTotal] = useState(0)
    const [classBooking, setClassBooking] = useState(0)
    const [classBookingELP, setClassBookingELP] = useState(0)
    const [classBalanceForBooking, setClassBalanceForBooking] = useState(0)
    const [classBalanceForBookingELP, setClassBalanceForBookingELP] = useState(0)
    const [classDone, setClassDone] = useState(0)
    const [classNoshow, setClassNoshow] = useState(0)
    const [classNoshowELP, setClassNoshowELP] = useState(0)
    const [classData, setClassData] = useState([])
    const [studentClassLastDate, setStudentClassLastDate] = useState(null)
    const [presentAge, setPresentAge] = useState(null)
    const [classAge, setClassAge] = useState(null)
    const [cssState, setCssState] = useState(null)
    useEffect(() => {
        fetch(`${url}/singleStudentView?studentDBID=${student.studentDBID}`)
            .then((res) => {
                return res.json();
            })
            .then((json) => {
                sessionStorage.setItem('student',JSON.stringify(json))
                return json

            }).then(json => {
                console.log(json)
                setClassTotal(json.classTotal)
                setClassBalance(json.classBalance)
                setClassBalanceELP(json.classBalanceELP)
                setClassBooking(json.classBooking)
                setClassBookingELP(json.classBookingELP)
                setClassBalanceForBooking(json.classBalanceForBooking)
                setClassBalanceForBookingELP(json.classBalanceForBookingELP)
                setClassDone(json.classDone)
                setClassNoshow(json.classNoshow)
                setClassNoshowELP(json.classNoshowELP)
                setStudentName(json.name)
                setStudentClassLastDate(json.studentClassLastDate)
                let tempAge = birthdayToAge(json.birthday)
                // setBirthdayToAge(tempAge)
                setPresentAge(tempAge[0])
                setClassAge(tempAge[1])
                console.log(tempAge)
                if (json.gender == "女") {
                    setCssState("card-body alert-danger")
                } else {
                    setCssState("card-body alert-info")
                }
            });

    }, [])

    return (
        <>
            <div className="card mb-2 text-left" >

                <div className={cssState}>
                <div className="row"  style={{"lineHeight":"20px"}}>
                       
                        <div className="col-6 text-left">
                            <h4 >{studentName} </h4>
                        </div>
                
                        <div className="col text-right font-weight-light">
                        {presentAge}
                        </div>
                    </div>
                   <hr className="mt-0 mb-0"/>
                    <div>
                    <div className="row no-gutters">
                    <div className="col-5">
                       <small>期限 : {studentClassLastDate}</small>
                       </div>
                       <div className="col ">
                       <small>區間：{classAge}</small>
                       </div>
               
                       
                       <div className="col ">
                       <small>待劃位: {classBalanceForBooking}</small>
                       </div>
                   </div>

                    </div>
                   
                </div>
            </div>



        </>
    );
}




export default StudentCard;