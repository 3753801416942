import React, { useState, useEffect, useCallback, updateState } from "react";
import { Link, useParams } from "react-router-dom";
import birthdayToAge from "../function/birthdayToAge.js";
import getDay from "../function/getDay.js";
import getUrl from "../function/getUrl.js";
import reloadIcon from "../icon/reload.svg";
import pencilIcon from "../icon/pencil.svg";
import checkLogin from "../function/checkLogin.js";
import logo from "../icon/good2swim-logo.png";
import StudentCard from "../pages/studentCard";
import studentMakeClassRecord from "../function/studentMakeClassRecord.js"

const $ = window.$
const token = sessionStorage.getItem("token");
//一日課表
const GetDailyTable = (props) => {

  const student = JSON.parse(sessionStorage.getItem("student"));
  // const studentDBID = student.studentDBID
  const [studentDBID, setStudentDBID] = useState(student.studentDBID)
  const [studentName, setStudentName] = useState(student.name);
  const [studentType, setStudentType] = useState(student.studentType)
  const [classBalance, setClassBalance] = useState(student.classBalance);
  const [classTotal, setClassTotal] = useState(student.classTotal);
  const [classBooking, setClassBooking] = useState(student.classBooking);
  const [classBalanceForBooking, setClassBalanceForBooking] = useState(student.classBalanceForBooking);
  const [classDone, setClassDone] = useState(student.classDone);
  const [classNoshow, setClassNoshow] = useState(student.classNoshow);
  const [classData, setClassData] = useState([]);
  const [studentClassLastDate, setStudentClassLastDate] = useState(student.studentClassLastDate);
  const [msg, setMsg] = useState(null)
  const [bookingClassDBID, setBookingClassDBID] = useState(null)
  // const [studentClassData, setStudentClassData] = useState(student.classList)
  const [btnTitle, setBtnTitle] = useState(<span className="text-dark font-weight-bold">我要劃位</span>)
  const [studentStateForClass, setStudentStateForClass] = useState(null)
  const [classList, setClassList] = useState(null)
  const [presentAge, setPresentAge] = useState(
    birthdayToAge(student.birthday, "-")[0]
  );
  const [classAge, setClassAge] = useState(
    birthdayToAge(student.birthday, "-")[1]
  );

  console.log("studentClassLastDate",studentClassLastDate)
  let param = useParams();
  let todayDate = param.getDate;
  console.log("todayDate", todayDate)
  let staff = [];

  const url = getUrl();
  if (todayDate == "") {
    todayDate = getDay(0, "-")
  }

  const [pickDate, setPickDate] = useState(todayDate);

  function todayAdd14Days(){
      
        let tempDate=new Date()
        let classEndDate=new Date(tempDate.setDate(tempDate.getDate()  + 14))


        let tempMonth=parseInt(classEndDate.getMonth(),"10")+1 
        let tempDate2=classEndDate.getDate()
        if (classEndDate.getDate() <10){
          tempDate2="0"+tempDate2
        }
        console.log("tempMonth",tempMonth)
        let result=classEndDate.getFullYear()+"-0"+tempMonth+"-"+tempDate2
        if (tempMonth >= 10){
          result= classEndDate.getFullYear()+"-"+tempMonth+"-"+tempDate2        }
        console.log("todayAdd14Days",result)
        return result

  }


  function getClassList(getDate) {
    return new Promise((resolve, reject) => {
      //mode: "cors"
      fetch(`${url}/getDailyClassTableAmount?date=${getDate}`, { mode: "cors" })
        .then((res) => {
          return res.json();
        })
        .then((json) => {
          json.sort((a, b) => {
            var nameA = a.classTime;
            var nameB = b.classTime;
            if (nameA > nameB) {
              return 1;
            }
            if (nameA < nameB) {
              return -1;
            }

            return 0;
          });

          resolve(json);
        });
    });
  }

  function nowTime(){
    let today=new Date();
    let nowTime=null
    if (today.getHours() < 10){
      nowTime="0"+today.getHours()+"59"
    }else{
      nowTime=today.getHours()+"59"
    }
    console.log(nowTime,today.getHours())
    return nowTime
   }

  function getStudentClassListData(studentDBID) {
    return new Promise((resolve, reject) => {
      //mode: "cors"
      fetch(`${url}/singleStudentView?studentDBID=${student.studentDBID}`)
        .then((res) => {
          return res.json();
        })
        .then((json) => {

          resolve(json.classList);
        });
    });
  }



  const classPart = async (getDate) => {



    let studentClassData = await getStudentClassListData(studentDBID)
    console.log("studentClassData", studentClassData)
    getClassList(getDate).then((json) => {
      //取得本日開課資料
      setClassData(json)


      console.log(json);
      let classArray = [];
      for (let x in json) {
        let CardCss = "card  mt-2 mb-1   ";
        let CardBodyCss = "card P-0 m-1 ";
        switch (json[x]["age"]) {
          case "3-6M":
            CardCss += "alert-primary";
            CardBodyCss += "alert-primary";
            break;
          case "6-12M":
            CardCss += "alert-secondary";
            CardBodyCss += "alert-secondary";
            break;
          case "1-2Y":
            CardCss += "alert-danger";
            CardBodyCss += "alert-danger";
            break;
          case "2-3Y":
            CardCss += "alert-info";
            CardBodyCss += "alert-info";
            break;
          case "3-7Y":
            CardCss += "alert-warning text-dark";
            CardBodyCss += "alert-warning";
            break;
          case "7-12Y":
            CardCss += "alert-success";
            CardBodyCss += "alert-success";
            break;
          case "ELP":
            CardCss += "alert-success";
            CardBodyCss += "alert-success";
            break;
          default:
            CardCss += "alert-secondary text-dark ";
            CardBodyCss += "alert-muted  ";
            break;
        }

          //防疫修訂 每班為4人--》開關
          // json[x]["limit"]="4"
        //處理空位顯示 
        let thisClassBalanceForBooking = parseInt(json[x]["limit"], "10") - parseInt(json[x]["booking"], "10") - parseInt(json[x]["done"], "10")
        //如果有人後補中，需櫃枱人工處理順位 且空位顯示為 0 
        if (parseInt(json[x]["backup"], "10") > 0) {
          thisClassBalanceForBooking = 0
        }

        // // 日期如果大於14天，則自動將餘課數-1 或改為0
        // console.log(json[x]["classDate"] ,"dkdkdkd", todayAdd14Days())
        // if (json[x]["classDate"] > todayAdd14Days()){
        //   if(thisClassBalanceForBooking >0){
        //     thisClassBalanceForBooking=thisClassBalanceForBooking-1
        //     if(thisClassBalanceForBooking < 1){
        //       thisClassBalanceForBooking=0
        //     }
        //   }
        // }
        // console.log(studentClassData, json[x]["classDBID"])

        let iAMinThisClass = studentClassData.find(item => {

          return item.classDBID == json[x]["classDBID"]
        })

        //判斷按鈕顯示畫面
        let classState = <span className="text-info font-weight-bold">我要劃位</span>
        //先判斷是否還有空位
        if (thisClassBalanceForBooking < 1) {
          //無空位
          if (iAMinThisClass) {
            switch (iAMinThisClass.studentState) {
              case "劃位":
                classState = <span className="text-muted font-weight-bold">已劃位</span>
                break;
              case "候補":
                classState = <span className="text-muted font-weight-bold">候補中</span>
                break;
              default:
                //原來為取消或融通取消者
                classState = <span className="text-muted font-weight-bold">我要候補</span>
                break;
            };
          } else {
            classState = <span className="text-muted font-weight-bold">我要候補</span>
          };
        } else {
          //有空位
          if (iAMinThisClass) {
            //原來已有動作者
            switch (iAMinThisClass.studentState) {
              case "劃位":
                classState = <span className="text-muted font-weight-bold">已劃位</span>
                break;
              default:
                //原來為取消或融通取消者
                classState = <span className="text-muted font-weight-bold">我要劃位</span>
                break;
            };
          } else {
            classState = <span className="text-muted font-weight-bold">我要劃位</span>
          };
        };

    




        console.log('thisClassBalanceForBooking', thisClassBalanceForBooking)
        let singleClass = (
          <>
            <div
              className={CardCss}
              onClick={() => {

                //處理按鍵動作
                // 先判斷是否還有可劃堂數

                if (classBalanceForBooking < 1) {
                  //無可劃空位者
                  let msgContext = <div>{studentName} 待劃位數不足 <br />現在沒辦法劃位唷!<br />需要協助嗎？!<br />請透過 Line@ 告訴我們</div>
                  setMsg(msgContext)
                  $('#popMsgModal').modal('show')
                  return

                } else {
                  //有空堂 
                  if (thisClassBalanceForBooking < 1) {
                    //無空位

                    if (iAMinThisClass) {
                      switch (iAMinThisClass.studentState) {
                        case "劃位":
                        case "候補":
                          break;
                        default:
                          let msgContext = `本班人數已滿安排候補嗎 ？`;
                          setStudentStateForClass('候補')
                          setBookingClassDBID(json[x]["classDBID"])
                          setMsg(msgContext)
                          $('#BackupConfirmModal').modal('show')
                          return
                      }

                    } else {
                      //加入後補行動
                      let msgContext = `本班人數已滿安排候補嗎 ？`;
                      setStudentStateForClass('候補')
                      setBookingClassDBID(json[x]["classDBID"])
                      setMsg(msgContext)
                      $('#BackupConfirmModal').modal('show')
                      return
                    };
                  } else {
                    //有空位
                    if (iAMinThisClass) {
                      switch (iAMinThisClass.studentState) {
                        case "劃位":
                          break;
                        default:
                          let msgContext = <div className="text-center">日期：{json[x]["classDate"]} <br />時間：{json[x]["classTime"]} 班別：{json[x]["age"]} <br />確定劃位嗎？  </div>
                          setStudentStateForClass('劃位')
                          setBookingClassDBID(json[x]["classDBID"])
                          setMsg(msgContext)
                          $('#confirmModal').modal('show')
                          return
                      }

                    } else {

                      //加入劃位行動
                      if (json[x]["classDate"] <= getDay(2, "-")) {
                        //開課日期為近二日內
                        let msgContext = <div>開課日期為{json[x]["classDate"]}  <br /> {getDay(0, "-")}當天及二日內的劃位<br />將無法取消，確定劃位嗎？
                        </div>
                        setMsg(msgContext)
                        setStudentStateForClass('劃位')
                        setBookingClassDBID(json[x]["classDBID"])
                        $('#confirmModal').modal('show')
                      } else {
                        let msgContext = <div className="text-center">日期：{json[x]["classDate"]} <br />時間：{json[x]["classTime"]} 班別：{json[x]["age"]} <br />確定劃位嗎？  </div>
                        setStudentStateForClass('劃位')
                        setBookingClassDBID(json[x]["classDBID"])
                        setMsg(msgContext)
                        $('#confirmModal').modal('show')
                      }

                    };
                  };

                };



              }}
            >
              {/* <span>
                <img src={logo} width="80px" />
              </span> */}

              <div className="container p-1">
                <div className="row no-gutters" >
                  <div className="col border p-1 border-1 border-light rounded-lift " style={{ lineHeight: "40pt" }}>
                    <span style={{ fontSize: "14pt" }}>{json[x]["age"]}</span> <br />
                  </div>
                  <div className="col-6 text-left  p-2  border-top  border-right border-bottom border-1 border-light rounded-right " >
                    開課時間：{json[x]["classTime"].substring(0, 2)}:
                {json[x]["classTime"].substring(2, 4)}
                    {/* <br /> <small>上限:{json[x]["limit"]} / 劃位:{json[x]["booking"]} / 候補:{json[x]["backup"]}{" "} */}
                    <br /> <small>空位:{thisClassBalanceForBooking} / 候補:{json[x]["backup"]}{" "}

                    </small>
                  </div>
                  <div className="col ml-2 p-1  rounded bg-light" style={{ fontSize: "10pt", width: "20pt", lineHeight: "40pt" }}>
                    {classState}
                  </div>
                </div>
              </div>

            </div>

          </>
        );

        // console.log(json[x]["classTime"])
       if (json[x]["classDate"]==todayDate && json[x]["classTime"] > nowTime() || json[x]["classDate"]>todayDate){
        if(json[x]["webApp"]=="true" &&json[x]["available"]=="true" && json[x]["age"]!="備用" ){
          classArray.push(singleClass);
    }
       }
           
       
      }
   
      if (classArray.length==0){
        let temp=<>
            <div className="alert alert-warning" ><h4>本日課程已結束</h4></div>
         
          </>
        classArray.push(temp)
      }
        setClassList(classArray);
      
      
    });
  }

  function handleChangeDate(e) {
   
    let today = getDay(0, "-");
    let pickDateE = e.target.value;
    console.log("pickDateE",pickDateE)
    console.log("studentClassLastDate",studentClassLastDate)
    if (pickDateE > studentClassLastDate){
      setPickDate(studentClassLastDate);
      let show=<><div className="alert alert-warning">課程使用期限已屆滿</div></>
      // document.getElementById("startDateValue").disabled=true
      setClassList(show)
    
      return 
    }

    // console.log("todayAdd14Days",todayAdd14Days())
    console.log("here",pickDateE,todayAdd14Days())
    if (pickDateE > todayAdd14Days()){
    
      setPickDate(today);
      let show=<><div className="alert alert-warning">請選擇14天內的課程劃位</div></>
      // document.getElementById("startDateValue").disabled=true
      setClassList(show)
    
     return 
    }
    console.log("this",pickDateE,today)
    if (pickDateE < today) {
      alert("只能選擇今天或未來的日期哦！");
      setPickDate(today);
      classPart(today);
      return;
    }
 

 
    
 
    
    setPickDate(pickDateE);
    classPart(pickDateE);
    // handleClick()
  }

  function handleClick() {
    let date = document.getElementById("startDateValue").value;

    classPart(date);
  }

  //劃位
  function bookingClass(thisClassAge) {
    //檢視年紀是否相符
    if (classAge != thisClassAge) {
      if (!window.confirm("確定？")) {
        return;
      }
    }
  }

  useEffect(() => {
    console.log(todayDate,studentClassLastDate)
    // if (todayDate > studentClassLastDate) {
      
    //   let msg=`您的期限是${studentClassLastDate} ，已經逾期囉！請洽服務人員`
    //   if (todayDate < "2021-07-01"){
    //     msg=`您的期限是${studentClassLastDate} ，請於 2021-6-30 前使用完畢，安排課程請洽服務人員`
    //   }
    //   setMsg(msg)
      
    //   $('#overLastDatePopMsgModal').modal('show')
    //   return 
    // }
   

    if (todayDate > studentClassLastDate){

      setPickDate(studentClassLastDate);
      let show=<><div className="alert alert-warning">課程使用期限已屆滿</div></>
      if (todayDate < "2021-07-01"){
        show=<><div className="alert alert-warning">{` 您的期限是${studentClassLastDate} ，課程使用期限已屆滿，請於融通期 2021-6-30 前使用完畢，如需排課安排課程請洽服務人員`}</div></>
        document.getElementById("startDateValue").disabled=true
      }else{
        document.getElementById("startDateValue").disabled=true
      }
      
      setClassList(show)

      return 
    }

      classPart(todayDate);
    
    

  }, []);

  return (
    <>
      <div
        className="container vw-75 text-center mt-3"
        style={{ maxWidth: "500px" }}
      >
        <StudentCard />
        <div className="card   alert-dark border-1  ">
          <h5 className=" mt-1  mb-1 p-1">{pickDate} 日課表</h5>
          <div className=" p-2 ml-2 mr-2">
            <div className="input-group mb-3 ">
              <div className="input-group-prepend">
                <span className="input-group-text " id="basic-addon1">
                  日期
                </span>
              </div>

              <input
                type="date"
                className="form-control"
                id="startDateValue"
                value={pickDate}
                onChange={handleChangeDate}
                aria-label="Username"
                aria-describedby="basic-addon1"
                
              />

              {/* <button
                className=" ml-1 btn btn-danger"
                style={{ width: "30%" }}
                onClick={handleClick}
              >
                送出
              </button> */}
            </div>
          </div>
        </div>
        {/* <h6 className="text-muted mt-3">點選課程劃位</h6> */}
        <hr />
        <div className="card  mt-1 border-0   ">{classList}</div>
      </div>




      {/*-----Modal----確認訊息-*/}
      <div className="modal fade" id="popMsgModal" tabindex="-1" role="dialog" aria-labelledby="exampleModalLabel" aria-hidden="true" modal="show">
        <div className="modal-dialog-centered modal-dialog" role="document">
          <div className="modal-content">
            <div className="modal-header">
              <h5 className="modal-title" id="exampleModalLabel">好好游學員APP</h5>
              <button type="button" className="close" data-dismiss="modal" aria-label="Close">
                <span aria-hidden="true">&times;</span>
              </button>
            </div>
            <div className="modal-body">
              {msg}
            </div>
            <div className="modal-footer ">
              <div className="btn alert-info ml-auto mr-auto" data-dismiss="modal" style={{ "width": "150pt" }}>確定</div>

            </div>
          </div>
        </div>
      </div>
      {/** ----劃位用 */}
      <div className="modal fade" id="confirmModal" tabindex="-1" role="dialog" aria-labelledby="exampleModalLabel" aria-hidden="true" modal="show">
        <div className="modal-dialog-centered modal-dialog" role="document">
          <div className="modal-content">
            <div className="modal-header">
              <h5 className="modal-title" id="exampleModalLabel">好好游學員APP</h5>
              <button type="button" className="close" data-dismiss="modal" aria-label="Close">
                <span aria-hidden="true">&times;</span>
              </button>
            </div>
            <div className="modal-body">
              {msg}
            </div>
            <div className="modal-footer ">

              <div className="btn alert-info ml-auto mr-auto" data-dismiss="modal" style={{ "width": "100pt" }} onClick={() => {
                //設計劃位動作function

                // let com = `${bookingClassDBID} ${studentDBID} ${studentStateForClass} - ${studentType}`
                // alert(com)
                let result = studentMakeClassRecord(bookingClassDBID, studentDBID, studentStateForClass, studentType)
                if (result) {
                  setMsg('安排劃位完成')
                  $('#donePopMsgModal').modal('show')

                } else {
                  setMsg('安排劃位失敗 請用 Line@ 反應給我們')

                  $('#donePopMsgModal').modal('show')
                }



              }}>確定</div>
              <div className="btn alert-dark ml-auto mr-auto" data-dismiss="modal" style={{ "width": "100pt" }}>取消</div>
            </div>
          </div>
        </div>
      </div>
  {/** ----候補用 */}
  <div className="modal fade" id="BackupConfirmModal" tabindex="-1" role="dialog" aria-labelledby="exampleModalLabel" aria-hidden="true" modal="show">
        <div className="modal-dialog-centered modal-dialog" role="document">
          <div className="modal-content">
            <div className="modal-header">
              <h5 className="modal-title" id="exampleModalLabel">好好游學員APP</h5>
              <button type="button" className="close" data-dismiss="modal" aria-label="Close">
                <span aria-hidden="true">&times;</span>
              </button>
            </div>
            <div className="modal-body">
              {msg}
            </div>
            <div className="modal-footer ">

              <div className="btn alert-info ml-auto mr-auto" data-dismiss="modal" style={{ "width": "100pt" }} onClick={() => {
                //設計劃位動作function

                // let com = `${bookingClassDBID} ${studentDBID} ${studentStateForClass} - ${studentType}`
                // alert(com)
                let result = studentMakeClassRecord(bookingClassDBID, studentDBID, studentStateForClass, studentType)
                if (result) {
                  setMsg('安排候補完成')
                  $('#donePopMsgModal').modal('show')

                } else {
                  setMsg('安排候補失敗 請用 Line@ 反應給我們')

                  $('#donePopMsgModal').modal('show')
                }



              }}>確定</div>
              <div className="btn alert-dark ml-auto mr-auto" data-dismiss="modal" style={{ "width": "100pt" }}>取消</div>
            </div>
          </div>
        </div>
      </div>

      {/** ----劃位完成使用 */}
      <div className="modal fade" id="donePopMsgModal" tabindex="-1" role="dialog" aria-labelledby="exampleModalLabel" aria-hidden="true" modal="show">
        <div className="modal-dialog-centered modal-dialog" role="document">
          <div className="modal-content">
            <div className="modal-header">
              <h5 className="modal-title" id="exampleModalLabel">好好游學員APP</h5>
              <button type="button" className="close" data-dismiss="modal" aria-label="Close">
                <span aria-hidden="true">&times;</span>
              </button>
            </div>
            <div className="modal-body">
              {msg}
            </div>
            <div className="modal-footer ">

              <div className="btn alert-info ml-auto mr-auto" data-dismiss="modal" style={{ "width": "100pt" }} onClick={() => {
                //設計劃位動作function
                window.location.href = "/pickupStudent/myRecord"
              }}>確定</div>

            </div>
          </div>
        </div>
      </div>

       {/** ----劃位完成使用 */}
       <div className="modal fade" id="overLastDatePopMsgModal" tabindex="-1" role="dialog" aria-labelledby="exampleModalLabel" aria-hidden="true" modal="show">
        <div className="modal-dialog-centered modal-dialog" role="document">
          <div className="modal-content">
            <div className="modal-header">
              <h5 className="modal-title" id="exampleModalLabel">好好游學員APP</h5>
              <button type="button" className="close" data-dismiss="modal" aria-label="Close">
                <span aria-hidden="true">&times;</span>
              </button>
            </div>
            <div className="modal-body">
              {msg}
            </div>
            <div className="modal-footer ">

              <div className="btn alert-info ml-auto mr-auto" data-dismiss="modal" style={{ "width": "100pt" }} onClick={() => {
                //設計劃位動作function
                window.location.href = "/pickupStudent/home"
              }}>確定</div>

            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default GetDailyTable;