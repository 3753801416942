
import React, { useState, useEffect } from 'react';
import checkLogin from "../function/checkLogin.js"
import getDay from "../function/getDay"
import getUrl from '../function/getUrl'
import logo from "../icon/good2swim-logo.png";
import checkStudent from '../function/checkStudent'
import pencilIcon from "../icon/cancel.svg";
import birthdayToAge from "../function/birthdayToAge.js"
import timeToClassTime from "../function/timeToClassTime.js"
const url = getUrl()
const MakeClass = () => {
    //確認系統storage中有指定學員資料
    checkStudent()
    const today=getDay(0,"-")
    const cancelDay=getDay(3,"-")
    let  time=new Date().getHours()
    if (time < 10){
        time="0"+time
    }
  
    const student = JSON.parse(sessionStorage.getItem("student"))
    const [studentName, setStudentName] = useState(student.name)
    const [classBalance,setClassBalance]=useState(0)
    const [classTotal,setClassTotal]=useState(0)
    const [classBooking,setClassBooking]=useState(0)
    const [classBalanceForBooking,setClassBalanceForBooking]=useState(0)
    const [classDone,setClassDone]=useState(0)
    const [classNoshow,setClassNoshow]=useState(0)
    const [classData,setClassData]=useState([])
    const [presentAge,setPresentAge]=useState(null)
    const [classAge,setClassAge]=useState(null)
    const [studentClassLastDate,setStudentClassLastDate]=useState(null)


   useEffect(()=>{
    fetch(`${url}/singleStudentView?studentDBID=${student.studentDBID}`)
    .then((res) => {
      return res.json();
    })
    .then((json) => {
        return json

      }).then(json=>{
        setClassTotal(json.classTotal)
        setClassBalance(json.classBalance)
        setClassBooking(json.classBooking)
        setClassBalanceForBooking(json.classBalanceForBooking)
        setClassDone(json.classDone)
        setClassNoshow(json.classNoshow)
        setStudentClassLastDate(json.studentClassLastDate)
        console.log(json.classList)
        let tempAge=birthdayToAge(json.birthday)
        setPresentAge(tempAge[0])
        setClassAge(tempAge[1])
        console.log(tempAge)
        return [tempAge[1],json.classList,json.studentClassLastDate,json.classBalanceForBooking]
    }).then((data)=>{
        console.log(data)
        const myClassList=data[1]
        const studentClassLastDate=data[2]
        const classBalanceForBooking=data[3]
        //捉課程資料
        fetch(`${url}/getClassByContext?age=${data[0]}`).then(res=>{
            return res.json()
        }).then(json=>{
            console.log(json)
            let endDate=getDay(7,"-")
            let today=getDay(0,"-")
            let nowTime=timeToClassTime()
            console.log("nowTime",nowTime)
            
          
            json.sort((a, b) => {
                var nameA = a.classDate;
                var nameB = b.classDate;
                if (nameA > nameB) {
                  return 1;
                }
                if (nameA < nameB) {
                  return -1;
                }
                return 0;
              })
              let count=0
              let tempClassList=[]
              json.map((item)=>{
                // console.log(item)
            //   判斷不在原有的已排課程清單中
              let findClassInMyCalssList=myClassList.find(MyclassListItem=>{
                  return MyclassListItem.classDBID == item.classDBID
              })
             
                //不在原來劃位課表中 且 日期在7天內 或 今天但開課時間大於現在時間 
              if (findClassInMyCalssList==undefined) {
                  if (item.classDate < endDate && item.classDate <= studentClassLastDate){
                    if (item.classDate==today && item.classTime < nowTime){
                        //do nothing
                    }else{
                        let emptyMark=parseInt(item.limit,"10")-parseInt(item.booking,"10")
                        if (emptyMark==0){
                            emptyMark="滿班"
                        }
                       let temp=<tr onClick={()=>{
                           window.location.href=`/bookingClass/${item.classDBID}`
                       }} >
                       <td>{item.classDate.substring(5,12)}</td>
                       <td>{item.classTime}</td>
                       <td>{item.age}</td>
                       <td>{emptyMark}</td>
                       
                       </tr>
       
                       tempClassList.push(temp)
                    }
                  }
                //空位數
                 
               
                
              }
             
               
                
            })
            console.log(tempClassList)
            setClassData(tempClassList)

        })
        

    })
        
        
     
      
   },[])

   

    return (
        <>
            <div className="card m-2" >
                <div className='card-body alert-info'>
                    <h4 className="card-title ">{studentName} </h4>
                <h6 className="card-title ">有效期限 : {studentClassLastDate} </h6>
                    <small>實際年紀：{presentAge} </small><br/>
                    <small>適合班別：{classAge}</small>
                </div>
            </div>
            <div className="card m-2" >
                <div className="card-body ">
                    <h5 className="card-title text-success ">課程狀態</h5>
                    <table className="table table-striped text-center text-muted">
                       
                    <tr>
                        <td>剩餘課</td>
                            <td>已劃位 </td>
                            <td>待劃位</td>
                        </tr>
                        <tr>
                            <td>{classBalance}</td>
                            <td>{classBooking} </td>
                            <td>{classBalanceForBooking}</td>
                        </tr>
                       
                    </table>
                </div>
            </div>

            <div className="card m-2" >
                <div className="card-body ">
                    <h5 className="card-title text-info ">一周內開課</h5>
                    <table className="table table-striped text-center text-info">
                        <tr>
                            <th scope="col">日期</th>
                            <th scope="col">時間</th>
                            <th scope="col">班別</th>
                            <th scope="col">空位</th>
                        </tr>
                       {classData}
                    </table>
                </div>
            </div>

           


        </>
    );
}




export default MakeClass;