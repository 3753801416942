import getUrl from "../function/getUrl.js";
const token = sessionStorage.getItem("token");



//取消劃位程式
const makeClassRecordCancel= async (studentDBID, classRecordDBID) =>{
    const url = getUrl();
    const singleStudentCmdUrl = `${url}/singleStudentView?studentDBID=${studentDBID}`
    console.log(singleStudentCmdUrl, classRecordDBID)

    
    //取得學員資訊 確認該課程存在
    let result=fetch(singleStudentCmdUrl).then(res => {
        return res.json()
    }).then(json => {
        console.log(json)
        let targetClass = json.classList.find(item => {
            console.log(item.classRecordDBID)
            return item.classRecordDBID == classRecordDBID
        })
    //     console.log(targetClass.studentDBID, targetClass.classRecordDBID)
    //     let msg=`確定取消 日期${targetClass.classDate} - ${targetClass.classTime} 劃位？`
       

    // if (!window.confirm(msg)) {
    //     window.location.href = "/home"
    //     return
    // } 
        return targetClass
    }).then(targetClass => {
        fetch(`${url}/makeClassRecordCancel?studentDBID=${targetClass.studentDBID}&classRecordDBID=${targetClass.classRecordDBID}`).then(res => {
            return res.status
        }).then(status=>{

            if (status=='200'){
                
                return true
            }
           return false
          
        }).catch(e=>{
            
            return false
          
        })
    })
        return result

    // const cmdUrl = `${url}/appGet/makeClassRecord?token=${token}&name=${item.name}&studentDBID=${item.studentDBID}&classDBID=${classDBID}&studentType=${item.studentType}&trialPayState=${item.trialPayState}&studentState=${item.studentState}&operator=${operator}&newRecord=${item.newRecord}&classRecordDBID=${item.classRecordDBID}`;
};

export default makeClassRecordCancel;
