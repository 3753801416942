import React from "react";
import FunBar from "./funBar.js"
import checkLoginForFunBar from "../function/checkLoginForFunBar"

// require('dotenv').config()
import logo from "../icon/good2swim-logo.png";
//預先檢查

let env = process.env.lindID;
//Icon




//功能列
const Navbar = (props) => {

  // var imgStyle = {
  //   width: "30px",
  //   height: "30px",
  // };

  // var fontStyle = {
  //   fontSize: "0.5em",
  // };

  let env = props.env;

  let functionMenu = <>
    <div className="navbar navbar-light navbar-expand-lg text-muted" align="center">
      <img src={logo} alt="logo" style={{ weight: "35px", height: "35px" }} /><h6>好好游 </h6>
    </div>
  </>
  if (  checkLoginForFunBar()) {
    functionMenu = <FunBar />
  }

  return (
    <>
    <div className="px-3" style={{ backgroundColor: "#F2F2F2" }}>
      {functionMenu}
    </div>
    <div className="px-3" style={{ "backgroundColor": "#F2F2F2","height":"30px" }}>
    
    </div>
    </>
  );
};

export default Navbar;
