import React, { useState, useEffect } from "react";
import checkLogin from "../function/checkLogin.js";
import getDay from "../function/getDay";
import getUrl from "../function/getUrl";
import logo from "../icon/good2swim-logo.png";
import checkStudent from "../function/checkStudent";
import pencilIcon from "../icon/cancel_red.svg";
import makeClassRecordCancel from "../function/makeClassRecordCancel.js";
import StudentCard from "../pages/studentCard";
const $ = window.$;
const url = getUrl();
const MyRecord = () => {
  //確認系統storage中有指定學員資料
  checkStudent();
  const today = getDay(0, "-");
  const cancelDay = getDay(2, "-");
  const [msg, setMsg] = useState(null);
  const student = JSON.parse(sessionStorage.getItem("student"));
  const [cancelClassReocrdDBID, setCancelClassRecordDBID] = useState(null);
  const [studentDBID, setStudentDBID] = useState(student.studentDBID);
  const [studentName, setStudentName] = useState(student.name);
  const [classBalance, setClassBalance] = useState(0);
  const [classTotal, setClassTotal] = useState(0);
  const [classBooking, setClassBooking] = useState(0);
  const [classBalanceForBooking, setClassBalanceForBooking] = useState(0);
  const [classDone, setClassDone] = useState(0);
  const [classNoshow, setClassNoshow] = useState(0);
  const [classData, setClassData] = useState([]);
  const [classListOrderByClassDate, setClassListOrderByClassDate] = useState([]);
  const [classDataSort, setClassDataSort] = useState([]);
  const [studentClassLastDate, setStudentClassLastDate] = useState(null);
  useEffect(() => {
    fetch(`${url}/singleStudentView?studentDBID=${student.studentDBID}`)
      .then((res) => {
        return res.json();
      })
      .then((json) => {
        return json;
      })
      .then((json) => {
        setClassTotal(json.classTotal);
        setClassBalance(json.classBalance);
        setClassBooking(json.classBooking);
        setClassBalanceForBooking(json.classBalanceForBooking);
        setClassDone(json.classDone);
        setClassNoshow(json.classNoshow);
        setStudentClassLastDate(json.studentClassLastDate);
        // json.classList.sort((a, b) => {
        //   var nameA = a.classDate;
        //   var nameB = b.classDate;
        //   if (nameA > nameB) {
        //     return 1;
        //   }
        //   if (nameA < nameB) {
        //     return -1;
        //   }
        //   return 0;
        // });
        setClassData(json.classList);
        // setClassListOrderByClassDate(json.classListOrderByClassDate)
        // json.classList.sort((a, b) => {
        //   var nameA = a.classDate;
        //   var nameB = b.classDate;
        //   if (nameA > nameB) {
        //     return -1;
        //   }
        //   if (nameA < nameB) {
        //     return -1;
        //   }
        //   return 0;
        // });
        setClassDataSort(json.classListOrderByClassDate);
      });
  }, []);

  return (
    <>
      <div className="m-2">
        <StudentCard />
      </div>

      <div className="card m-2">
        <div className="card-body ">
          <h5 className="card-title text-info ">即將上課</h5>
          <small className="text-success ml-3 ">
            提醒您：取消劃位要在上課前二天完成哦！
          </small>
          <table className="table table-striped text-center text-info">
            <tr>
              <th scope="col">日期</th>
              <th scope="col">時間</th>
              <th scope="col">狀態</th>
              <th scope="col">變更</th>
            </tr>

            {classData.map((item) => {
              let studentStateShow = "已劃位";
              if (item.studentState == "候補") {
                studentStateShow = "候補中";
              }

              if (item.studentState == "完課") {
                studentStateShow = "已完課";
              }

              if (item.classDate >= cancelDay && item.studentState != "取消") {
                return (
                  <tr
                    onClick={() => {
                      let msgContext = (
                        <div className="text-center">
                          日期：{item.classDate} <br />
                          時間：{item.classTime} 班別：{item.age} <br />
                          確定取消劃位嗎？{" "}
                        </div>
                      );
                      setCancelClassRecordDBID(item.classRecordDBID);
                      setMsg(msgContext);
                      $("#cancelConfirmModal").modal("show");
                    }}
                  >
                    <td>{item.classDate}</td>
                    <td>{item.classTime}</td>
                    <td>{studentStateShow}</td>
                  
                    <td><div className="btn alert-danger  " style={{ fontSize: "10pt"}}>取消</div></td>
                    
                  </tr>
                );
              } else if (item.classDate >=getDay(0,"-")  && item.studentState =="候補" ){
               
                return (
                <tr onClick={()=>{
                    let msgContext = <div className="text-center">日期：{item.classDate} <br />時間：{item.classTime} 班別：{item.age} <br />確定取消候補嗎？  </div>
  
                    setCancelClassRecordDBID(item.classRecordDBID)
                    setMsg(msgContext)
                    $('#cancelBackUPConfirmModal').modal('show')
                    // makeClassRecordCancel(item.studentDBID,item.classRecordDBID)
                    
                }} >
                <td>{item.classDate}</td>
                <td>{item.classTime}</td>
                <td>{studentStateShow}</td>
                <td><div className="btn alert-danger " style={{ fontSize: "10pt"}}>取消</div></td>
                </tr>
  
  )
             
            }else if (
                item.classDate >= today &&
                item.studentState == "劃位"
              ) {
                return (
                  <tr clasName="text-muted">
                    <td>{item.classDate}</td>
                    <td>{item.classTime}</td>
                    <td>{studentStateShow}</td>
                    <td></td>
                  </tr>
                );
              }
            })}
          </table>
        </div>
      </div>

      <div className="card m-2">
        <div className="card-body ">
          <h5 className="card-title text-muted ">上課記錄</h5>
          <table className="table table-striped text-center text-muted">
            <tr>
            {/* <th scope="col">#</th> */}
              <th scope="col">日期</th>
              <th scope="col">時間</th>
              <th scope="col">狀態</th>
            </tr>

            {classDataSort.map((item,index) => {
              if (
                item.classDate < today &&
                item.studentState != "取消" &&
                item.studentState != "候補" &&
                item.studentState != "劃位" &&
                item.studentState != "融通取消"
              ) {

                let classStudentType=null
                if (item.studentType=="體驗"){
                  classStudentType="體驗"
                }
                return (
                  <tr>
                     {/* <td>{index+1}</td> */}
                    <td>{item.classDate}</td>
                    <td>{item.classTime}</td>
                  
                    <td>{classStudentType}{item.studentState}</td>
                  </tr>
                );
              }
            })}
          </table>
        </div>
      </div>
      {/** ----取消使用 */}
      <div
        className="modal fade"
        id="cancelConfirmModal"
        tabindex="-1"
        role="dialog"
        aria-labelledby="exampleModalLabel"
        aria-hidden="true"
        modal="show"
      >
        <div className="modal-dialog-centered modal-dialog" role="document">
          <div className="modal-content">
            <div className="modal-header">
              <h5 className="modal-title" id="exampleModalLabel">
                好好游學員APP
              </h5>
              <button
                type="button"
                className="close"
                data-dismiss="modal"
                aria-label="Close"
              >
                <span aria-hidden="true">&times;</span>
              </button>
            </div>
            <div className="modal-body">{msg}</div>
            <div className="modal-footer ">
              <div
                className="btn alert-info ml-auto mr-auto"
                data-dismiss="modal"
                style={{ width: "100pt" }}
                onClick={() => {
                  let result = makeClassRecordCancel(
                    studentDBID,
                    cancelClassReocrdDBID
                  );
                  // let com = `${bookingClassDBID} ${studentDBID} ${studentStateForClass} - ${studentType}`
                  // alert(com)
                  // let result = studentMakeClassRecord(bookingClassDBID, studentDBID, studentStateForClass, studentType)
                  if (result) {
                    setMsg("取消完成");
                    $("#donePopMsgModal").modal("show");
                  } else {
                    setMsg("取消作業失敗 請用 Line@ 反應給我們");

                    $("#donePopMsgModal").modal("show");
                  }
                }}
              >
                確定
              </div>
              <div
                className="btn alert-dark ml-auto mr-auto"
                data-dismiss="modal"
                style={{ width: "100pt" }}
              >
                取消
              </div>
            </div>
          </div>
        </div>
      </div>

      {/** ----完成使用 */}
      <div
        className="modal fade"
        id="donePopMsgModal"
        tabindex="-1"
        role="dialog"
        aria-labelledby="exampleModalLabel"
        aria-hidden="true"
        modal="show"
      >
        <div className="modal-dialog-centered modal-dialog" role="document">
          <div className="modal-content">
            <div className="modal-header">
              <h5 className="modal-title" id="exampleModalLabel">
                好好游學員APP
              </h5>
              <button
                type="button"
                className="close"
                data-dismiss="modal"
                aria-label="Close"
              >
                <span aria-hidden="true">&times;</span>
              </button>
            </div>
            <div className="modal-body">{msg}</div>
            <div className="modal-footer ">
              <div
                className="btn alert-info ml-auto mr-auto"
                data-dismiss="modal"
                style={{ width: "100pt" }}
                onClick={() => {
                  //設計劃位動作function
                  window.location.href = "/pickupStudent/myRecord";
                }}
              >
                確定
              </div>
            </div>
          </div>
        </div>
      </div>
        {/** ----取消使用 */}
<div className="modal fade" id="cancelBackUPConfirmModal" tabindex="-1" role="dialog" aria-labelledby="exampleModalLabel" aria-hidden="true" modal="show">
        <div className="modal-dialog-centered modal-dialog" role="document">
          <div className="modal-content">
            <div className="modal-header">
              <h5 className="modal-title" id="exampleModalLabel">好好游學員APP</h5>
              <button type="button" className="close" data-dismiss="modal" aria-label="Close">
                <span aria-hidden="true">&times;</span>
              </button>
            </div>
            <div className="modal-body">
              {msg}
            </div>
            <div className="modal-footer ">

              <div className="btn alert-info ml-auto mr-auto" data-dismiss="modal" style={{ "width": "100pt" }} onClick={() => {
            
                let result=makeClassRecordCancel(studentDBID,cancelClassReocrdDBID)
                // let com = `${bookingClassDBID} ${studentDBID} ${studentStateForClass} - ${studentType}`
                // alert(com)
                // let result = studentMakeClassRecord(bookingClassDBID, studentDBID, studentStateForClass, studentType)
                if (result) {
                  setMsg('候補取消完成')
                  $('#donePopMsgModal').modal('show')

                } else {
                  setMsg('取消作業失敗 請用 Line@ 反應給我們')

                  $('#donePopMsgModal').modal('show')
                }



              }}>確定</div>
              <div className="btn alert-dark ml-auto mr-auto" data-dismiss="modal" style={{ "width": "100pt" }}>取消</div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default MyRecord;
