
import React, { useState, useEffect } from 'react';
import checkLogin from "../function/checkLogin.js"
import getDay from "../function/getDay"
import getUrl from '../function/getUrl'
import logo from "../icon/good2swim-logo.png";
import checkLineLogin from "../function/checkLineLogin"
import { Link, useParams } from "react-router-dom";
import liff from '@line/liff';
// import dotenv from "dotenv"
import covid19 from "../icon/covid19.png";
// require('dotenv').config()

//預先檢查

let liffId = process.env.REACT_APP_liffId;
const url = getUrl()
const Login = () => {
    const [lineResult, setLineResult] = useState([])
    useEffect(async () => {

        setTimeout(async ()=>{
            let data = await liff.init({ liffId: liffId }).then(async () => {
                if (liff.isInClient()) {
                    await liff.getProfile().then(profile => {
                        const lineID = profile.userId //處理 LineID
                        //  alert(lineID)
    
                        sessionStorage.setItem('lineID', lineID)
                        return lineID
                    }).then(async (lineID) => {
                        let msg = "second" + lineID
                        //  alert(msg)
                        let data = await fetch(`${url}/checkLineID?lineID=${lineID}`).then(res => res.json()).then(json => {
                            // alert(`phone :${json[0]["phone"]}&password=${json[0]["password"]}`)
                            if (json[0]["phone"] == undefined) {
                                //代表有LineID 不知道是誰
                                window.location.href = "/loginWithLineID"
                            }
    
                            return json
    
    
                        }).catch(e => {
                            //LineID 不存在
                            window.location.href = "/login"
                        })
                        return data
                    }).then(async (json) => {
                        // alert('third')
                        //  alert(`third: ${url}/login?phone=${json[0]["phone"]}&password=${json[0]["password"]}`)
                        // alert('here')
                        // document.getElementById('show').innerHTML = json[0]["phone"]
                        await fetch(`${url}/login?phone=${json[0]["phone"]}&password=${json[0]["password"]}`, {
                            mode: 'cors',
                        }).then(res => {
                            return res.json();
                        }).then(json => {
    
                            //  alert('fourth')
                            if (json.length > 0) {
                                sessionStorage.setItem('studentData', JSON.stringify(json));
    
                                window.location.href = "/pickupStudent/home"
    
                            } else {
                                window.location.href = "/login"
                            }
    
                        })
                    })
    
    
                } else {
                    window.location.href = "/login"
                }
    
    
            })
        },1000)
       


        // let result=await liff.init({ liffId: '1655965506-jAvBNGVM' }).then(async () => {
        //     if (liff.isInClient()) {

        //             liff.getProfile().then(async (profile) => {

        //                 const lineID = profile.userId //處理 LineID
        //                 let kk=`checkLineID:${lineID}`
        //                 alert(kk)
        //                 sessionStorage.setItem("lineID",lineID)

        //                await fetch(`${url}/checkLineID?lineID=${lineID}`).then(res => res.json()).then(async (json) => {
        //                     // alert(json[0]["phone"])
        //                     if (json[0]["phone"]!="") {
        //                         //如果有手機有找到 則直接登入

        //                         // good2swimLoginByPhonePassword(json[0]["phone"],json[0]["password"])
        //                         await fetch(`${url}/login?phone=${json[0]["phone"]}&password=${json[0]["password"]}`, {
        //                             mode: 'cors',
        //                         }).then(res => {
        //                             return res.json();
        //                         }).then(json => {


        //                             if (json.length > 0) {
        //                                 sessionStorage.setItem('studentData', JSON.stringify(json));

        //                                 window.location.href = "/pickupStudent/home"

        //                             } 

        //                         })
        //                     }else{
        //                         window.location.href=`/loginWithLineID/${lineID}`
        //                     }


        //                 })
        //             })

        //     }else{
        //         //轉入頁面
        //        window.location.href="/login"
        //     }
        // })
    }, [])

    return (
        <>
            {/* <h3 className="text-danger">測試版 {env}</h3> */}
            {/* <h5 className="text-center">Good2Swim</h5> */}
            <div id="show"></div>
            <div className="container  " style={{ maxWidth: "520px" }}>
                <div role="alert" color="warning">
                    {/* <div style={{ textAlign: 'center' }}>
                        <img src={logo} alt="logo" style={{ weight: "120px", height: "120px" }} />
                    </div> */}

                    <div className="card m-3" >
                        <img src={covid19} className="card-img-top" alt="..." />
                        <div className="card-body p-2 ">
                            <h5 className="card-title text-center text-info">我們用心，寶貝安心</h5>
                            <p className="card-text text-center text-muted">每日次氯酸加強清潔、閉館紫外線消毒</p>

                        </div>
                    </div>



                </div>



            </div>

        </>
    );
}




export default Login;