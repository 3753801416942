import React, { useState, useEffect } from "react";
import { Link, useParams } from "react-router-dom";
import logo from "../icon/good2swim-logo.png";
import HomeSVG from "../icon/house.svg";
import eject from "../icon/eject.svg";
import studentIcon from "../icon/people.svg";
import saleIcon from "../icon/sale.svg";
import adminIcon from "../icon/admin.svg";
import classIcon from "../icon/swimClass.svg";
import starIcon from "../icon/star.svg";
import pencilIcon from "../icon/pencil.svg";
import SearchIcon from "../icon/search.svg";
import getDay from "../function/getDay"
import getUrl from '../function/getUrl'
// let env = process.env;
// console.log(env)
const FunBar = (props) => {
  let studentDBID = props.studentDBID;
  let studentEdit = "/studentEdit/" + studentDBID;
  let newOrder = "/newOrder/" + studentDBID;
  let makeClassRecord = "/makeClassRecord/" + studentDBID;
  let makeLotClassReocrd = "/makeLotClassReocrd/" + studentDBID;
  let todayDate =getDay(0,"-")
  let url=getUrl()
  const version = "V 3.0.1 ";
  var imgStyle = {
    width: "30px",
    height: "30px",
  };

  var fontStyle = {
    fontSize: "0.5em",
  };
  //menu Bar 元作
  return (
    <nav className="navbar  rounded p-1 ">
     
      <div>
        <Link className="navbar-brand ml-auto" to={`/pickupStudent/home`} style={{ textAlign: "center" }}>
          <img src={HomeSVG} style={imgStyle} alt="Home" />
         
          <div className="text-muted " style={fontStyle}>
            {" "}
            Home
          </div>
        </Link>
      </div>
      {/* <div>
        <Link className="navbar-brand" to={`/pickupStudent/makeClass`} style={{ textAlign: "center" }}>
          <img src={pencilIcon}   style={{width:"27px"}} alt="Home" />
          <div className="text-muted mr-auto" style={fontStyle}>
            {" "}
            劃位
          </div>
        </Link>
      </div> */}
       <Link
          className="navbar-brand"
          to={`/pickupStudent/GetDailyTable`}
          style={{ textAlign: "center" }}
        >
          <img src={pencilIcon} width="25px" alt="Home" />
          <div className="text-muted" style={fontStyle}>
            {" "}
            我要劃位
          </div>
        </Link>
      <div>
        <Link
          className="navbar-brand"
          to={`/pickupStudent/myRecord`}
          style={{ textAlign: "center" }}
        >
          <img src={saleIcon} style={imgStyle} alt="Home" />
          <div className="text-muted" style={fontStyle}>
            {" "}
            我的課表
          </div>
        </Link>
      </div>
      <div>
        {/* <Link
          className="navbar-brand"
          to={`/GetDailyTable`}
          style={{ textAlign: "center" }}
        >
          <img src={saleIcon} style={imgStyle} alt="Home" />
          <div className="text-muted" style={fontStyle}>
            {" "}
            好好游日課表
          </div>
        </Link> */}
      </div>
      <div>
        <div
          className="navbar-brand"
          onClick={()=>{
           
            // alert(studentDBID)
            let data=JSON.parse(sessionStorage.getItem('studentData'))
            let phone=data[0]["phone"]
            // alert(data[0]["phone"])
            fetch(`${url}/logout?phone=${phone}`).then(res=>res.json()).then(json=>{
              sessionStorage.clear()
              window.location.href="/"
            })
           

          }}
          style={{ textAlign: "center" }}
        >
          <img src={eject} style={imgStyle} alt="Home" />
          <div className="text-muted" style={fontStyle}>
            {" "}
            登出
          </div>
        </div>
      </div>
    </nav>
    
  );
};

export default FunBar;
