function timeToClassTime(){
	let  time= new Date().getHours()+"00"



	if (time.length==3){
		time="0"+time
	}
		
    return time
}

export default timeToClassTime;