
function checkLogin(){
    const studentDBID=sessionStorage.getItem("studentData")
   
    //console.log("timecode",timecode)
    if (studentDBID){

        return true
    }
    
return false

}

export default checkLogin;