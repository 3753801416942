import getUrl from "./getUrl.js"

const  studentMakeClassRecord=async (classDBID,studentDBID,studentState,studentType)=>{
//劃課


let msg=`${studentDBID} ${classDBID} ${studentState} ${studentType}`
// alert(msg)
const url=getUrl()
if (!studentDBID || !classDBID ||!studentState||!studentType){
    return false
}

let result= await fetch(`${url}/studentMakeClassRecord?classDBID=${classDBID}&studentDBID=${studentDBID}&studentState=${studentState}&studentType=${studentType}`).then(res=>{
    console.log(res.status)
    return res.status
}
   ).then(status=>{

    if (status=='200'){
        
        return true
    }
   return false
  
}).catch(e=>{
    
    return false
  
})
return result

};

export default studentMakeClassRecord;