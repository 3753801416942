import React, { useState, useEffect } from "react";

import { Link, useParams } from "react-router-dom";
import logo from "../icon/good2swim-logo.png";
import HomeSVG from "../icon/house.svg";
import eject from "../icon/eject.svg";
import studentIcon from "../icon/people.svg";
import saleIcon from "../icon/sale.svg";
import adminIcon from "../icon/admin.svg";
import classIcon from "../icon/swimClass.svg";
import starIcon from "../icon/star.svg";
import pencilIcon from "../icon/pencil.svg";
import personIcon from "../icon/person.svg";
import getDay from "../function/getDay.js"
import checkLogin from "../function/checkLogin.js"
import coachIcon from "../icon/coach.svg"
import checkLoginForFunBar from "../function/checkLoginForFunBar"

const HeaderBar = (props) => {
  const name = sessionStorage.getItem('name')
  const timecode = sessionStorage.getItem("timeCode")
  const operator = sessionStorage.getItem("staffDBID")
  const now = Date.now()

  

  const today = getDay(0, "-")

  var imgStyle = {
    width: "30px",
    height: "30px",
  };

  var fontStyle = {
    fontSize: "0.5em",
  };




  return (

    <>
      <nav className="navbar navbar-light navbar-expand-lg   " style={{ backgroundColor: "#F2F2F2" }}>
        <div className="mr-auto">
        
            <img src={logo} alt="logo" style={{ weight: "35px", height: "35px" }} />
  
        </div>
        <div className=" text-muted">
     <h6>好好游。學員專區</h6>
    </div>


      </nav>
    </>
  );
};

export default HeaderBar;
