import React, { useState, useEffect } from "react";
import { useForm } from "react-hook-form";
import logo from "../icon/good2swim-logo.png";
import bgPic from "../icon/EW2A1883.jpg";
import getUrl from "../function/getUrl.js";
import getDay from "../function/getDay";


function handleBirthdayChangeToPassword(e) {
  let  birthdayDate = document.getElementById('birthday').value
 
    return  birthdayDate.replaceAll("-", "").substring(4, 8)
   
  
  
}
export default function TrialApp() {
    const $ = window.$;
  const { register, handleSubmit, watch, formState: { errors } } = useForm();
  const onSubmit = data => {console.log(data)
    var formData = new FormData();
    // let password = makePassword(data.birthday);
    let password=handleBirthdayChangeToPassword()
    // alert(password)
    // console.log(password)
    formData.append("name", data.name);

    formData.append("studentType", "體驗");
    formData.append("birthday", data.birthday);
    formData.append("gender", data.gender);
    formData.append("comefrom", "trialPage");
    if (data.memo=="undefined"){
        data.memo=""
    }
    formData.append("memo", data.memo);

    if (data.healthMemo=="undefined"){
        data.healthMemo=""
    }
    formData.append("healthMemo", data.healthMemo);
   
    if (data.swimExp=="undefined"){
        data.swimExp=""
    }
    formData.append("swimExp", data.swimExp);

    formData.append("parent", data.parent);
    formData.append("title", data.title);
    formData.append("phone", data.phone);
    formData.append("fb", data.fb);

    if (data.line=="undefined"){
        data.line=""
    }
    formData.append("line", data.line);
    formData.append("contactGood2swim", data.contactGood2swim);
    formData.append("email", data.email);
    formData.append("nickName", data.nickName);
    formData.append("wishTime", data.wishTime);
    if (data.introPeople=="undefined"){
        data.introPeople=""
    }

    formData.append("introPeople", data.introPeople);

    formData.append("password", password);

    fetch(`${url}/addNewStudentTrial`, {
      method: "POST",
      body: formData,
      mode: "cors",
    })
      .then((res) => {
        return res.json();
      })


      setMsg("體驗報名完成，別忘了至官方 Line@ 私訊好好游小編哦！")
      $('#remindPopMsgModal').modal('show')





};
  const url = getUrl();
  const today = getDay(0, "-");
  console.log(watch("example")); // watch input value by passing the name of it
  const [msg, setMsg] = useState(null)



  function makePassword(date) {
    let birthday = new Date(date);
    let month = birthday.getMonth();
    let day = birthday.getDay();

    if (day < 9) {
      day = "0" + day;
    }

    if (month < 9) {
      month = "0" + (1 + month);
    } else {
      month = 1 + month;
    }
    return month + day;
  }


  return (
    <>


   
      <div className="container vw-75 rounded" style={{ maxWidth: "700px" }}>
        <div
          className="card   p-1   border-0"
          style={{ backgroundColor: "#75B8A9" }}
        >
          <img
            src={bgPic}
            style={{ width: "100%" }}
            className="align-middle rounded"
          />
          <div className="card-body p-1" style={{ backgroundColor: "#D8EFDC" }}>
            <div className="col-sm align-middle text-center  rounded">
              <img src={logo} style={{ width: "50px" }} />
              <h5 className="card-title text-info">好好游 體驗報名專區</h5>
            </div>

            <div className="container vw-100 rounded " style={{ maxWidth: "700px" }}>
        <div className="card border-0">
          <div class="card-header alert-warning">寶貝體驗報名 注意事項</div>
          <div class="card-body">
            <p class="card-text text-muted text-left">
            ①館內每位下水的ＢＡＢＹ都必須穿著 「寶寶專用泳褲」及 「游泳專用尿布」，方可入池參加活動。
若無法即時自行準備，可於現場購買 瑞典品牌 ImseVimse 寶寶泳褲($1080)、 一次性游泳尿布($60)。
              <br />
              <br />
              ②每名學員僅限報名體驗一次，優惠價 $1200 (含一大一小)
請於預約體驗時段前 3 日完成付款，為確保寶貝的體驗名額，完成匯款並告知後五碼才算預約成功，否則系統將自動取消恕不保留體驗名額。
              <br />
              <br />
              ③為確保首次下水的寶貝最完整的照顧及說明，報名體驗的學員將會安排適齡時段。體驗後可再挑選適合之常規時段。
              <br />
              <br />
              ④若有不可抗拒或身體不適…等因素請勿下水，並於症狀緩解後幾日，諮詢醫生意見後再另行安排課程，均可更改預約體驗時段。
              <br />
              <br />
              ⑤填寫完畢請加入 好好游官方Line@ ，並主動告知寶貝的姓名，以便小編儘速聯絡您安排體驗時間。
              <br />
              <br />
              ⑥本次資料收集僅作本次體驗使用，為了解您和寶寶過去的經驗以提供適合的服務，請務必確實填寫。
              <br />
              <br />
              ⑦此次體驗活動期間，本公司可能會拍攝照片及影片以供本公司網站、粉絲專業、社群媒體使用。若您及寶寶的肖像權不願意授權給本公司使用，請於活動開始前主動以書面告知本公司。
              <br />
            </p>

            <div
              className="form-group card border-0 p-2  "
              style={{ width: "100%" }}
            >
              
            </div>
          </div>
        </div>
      </div>
    



    <div className="container vw-100 " style={{ maxWidth: "600px" }}>
        <form onSubmit={handleSubmit(onSubmit)}>
          <div className="card mt-2 border-0">
            <div className="card-header alert-info">Baby 資料區</div>
            <div className="card-body">
              <div className="m-1">
                <div className="input-group">
                  <div className="input-group-prepend">
                    <span
                      className="input-group-text alert-info"
                      id="validationTooltipUsernamePrepend"
                    >
                      姓名
                    </span>
                  </div>
                  <input
                    type="text"
                    id="name"
                    className="form-control"
                    placeholder="請填寫中文姓名 方便確認"
                    {...register("name", { required: true, maxLength: 20 })}
                    required
                  />
                </div>
              </div>
              <div className="m-1">
                <div className="input-group">
                  <div className="input-group-prepend">
                    <span
                      className="input-group-text alert-info"
                      id="validationTooltipUsernamePrepend"
                    >
                      小名
                    </span>
                  </div>
                  <input
                    type="text"
                    id="nickName"
                    className="form-control"
                    placeholder="可填寫暱稱、英文姓名"
                    {...register("nickName", { required: true, maxLength: 20 })}
                    required
                  />
                </div>
              </div>

              <div className="m-1">
                <div className="input-group">
                  <div className="input-group-prepend">
                    <span
                      className="input-group-text alert-info"
                      id="validationTooltipUsernamePrepend"
                    >
                      生日
                    </span>
                  </div>
                  <input
                    type="date"
                    id="birthday"
                    className="form-control"
                    {...register("birthday", { required: true, maxLength: 20 })}
                    required
                  />
                </div>
              </div>

              <div className="m-1">
                <div className="input-group">
                  <div className="input-group-prepend">
                    <span
                      className="input-group-text alert-info"
                      id="validationTooltipUsernamePrepend"
                    >
                      性別
                    </span>
                  </div>
                  <select id="gender" className="custom-select" 
                    {...register("gender", { required: true, maxLength: 20 })}
                  required>
                    <option disabled selected hidden>
                      請選擇
                    </option>
                    <option value="男">Boy</option>
                    <option value="女">Girl</option>
                  </select>
                </div>
              </div>

              <div className="m-1">
                <div className="input-group">
                  <div className="input-group-prepend">
                    <span
                      className="input-group-text alert-info"
                      id="validationTooltipUsernamePrepend"
                    >
                      游泳經驗
                    </span>
                  </div>
                  <input
                    type="text-area"
                    id="swimExp"
                    className="form-control"
                    placeholder="請描述游泳經驗"
                   
                    {...register("swimExp", {  maxLength: 50 })}
                  />
                </div>
              </div>

              <div className="m-1">
                <div className="input-group">
                  <div className="input-group-prepend">
                    <span
                      className="input-group-text alert-info"
                      id="validationTooltipUsernamePrepend"
                    >
                      健康描述
                    </span>
                  </div>
                  <input
                    type="text-area"
                    id="healthMemo"
                    className="form-control"
                    placeholder="有無特殊體況"
                   
                    {...register("healthMemo", {  maxLength: 50 })}
                  />
                </div>
              </div>
            </div>
          </div>

          <div className="card mt-2 border-0">
            <div className="card-header alert-primary">家長 資料區</div>
            <div className="card-body">
              <div className="m-1">
                <div className="input-group">
                  <div className="input-group-prepend">
                    <span
                      className="input-group-text alert-primary"
                      id="validationTooltipUsernamePrepend"
                    >
                      姓名
                    </span>
                  </div>
                  <input
                    type="text"
                    id="parent"
                    className="form-control"
                    placeholder="請填寫真實姓名"
                    {...register("parent", { required: true, maxLength: 20 })}
                    required
                  />
                </div>
              </div>
              <div className="m-1">
                <div className="input-group">
                  <div className="input-group-prepend">
                    <span
                      className="input-group-text alert-primary"
                      id="validationTooltipUsernamePrepend"
                    >
                      稱謂
                    </span>
                  </div>
                  <input
                    type="text"
                    id="title"
                    className="form-control"
                    placeholder="填寫稱謂"
                    {...register("title", { required: true, maxLength: 10 })}
                    required
                  />
                </div>
              </div>

              <div className="m-1">
                <div className="input-group">
                  <div className="input-group-prepend">
                    <span
                      className="input-group-text alert-primary"
                      id="validationTooltipUsernamePrepend"
                    >
                      行動電話
                    </span>
                  </div>
                  <input
                    type="text"
                    id="phone"
                    className="form-control"
                    placeholder="必填項目"
                    {...register("phone", { required: true, maxLength: 15 })}
                    required
                  />
                </div>
              </div>

              <div className="m-1">
                <div className="input-group">
                  <div className="input-group-prepend">
                    <span
                      className="input-group-text alert-primary"
                      id="validationTooltipUsernamePrepend"
                    >
                      Line帳號
                    </span>
                  </div>
                  <input
                    type="text"
                    id="line"
                    className="form-control"
                    {...register("line")}
                    placeholder="請填寫顯示名稱"
                  />
                </div>
              </div>

              {/* <div className="m-1">
                <div className="input-group">
                  <div className="input-group-prepend">
                    <span
                      className="input-group-text alert-primary"
                      id="validationTooltipUsernamePrepend"
                    >
                      FB帳號
                    </span>
                  </div>
                  <input
                    type="text"
                    id="fb"
                    className="form-control"
                    placeholder="請填寫FB顯示名稱"
                    {...register("fb", { required: true, maxLength: 30 })}
                    required
                  />
                </div>
              </div> */}

              <div className="m-1">
                <div className="input-group">
                  <div className="input-group-prepend">
                    <span
                      className="input-group-text alert-primary"
                      id="validationTooltipUsernamePrepend"
                    >
                      曾私訊好好游小編
                    </span>
                  </div>

                  <select
                    id="contactGood2swim"
                    className="custom-select"
                    {...register("contactGood2swim", { required: true })}
                    required
                  >
                    <option disabled selected hidden>
                      請選擇
                    </option>
                    <option value="Yes" >Yes </option>
                    <option value="No" >No 稍等立即私訊小編</option>
                  </select>
                </div>
              </div>

              <div className="m-1">
                <div className="input-group">
                  <div className="input-group-prepend">
                    <span
                      className="input-group-text alert-primary"
                      id="validationTooltipUsernamePrepend"
                    >
                      E-mail
                    </span>
                  </div>
                  <input
                    type="email"
                    id="email"
                    className="form-control"
                    placeholder="E-mail"
                    
                    {...register("email", { required: true, maxLength: 50 })}
                    required
                  />
                </div>
              </div>

              <div className="m-1">
                <div className="input-group">
                  <div className="input-group-prepend">
                    <span
                      className="input-group-text alert-primary"
                      id="validationTooltipUsernamePrepend"
                    >
                      期望體驗時段
                    </span>
                  </div>

                  <select
                    id="wishTime"
                    className="custom-select"
                    multiple="multiple"
                    {...register("wishTime", { required: true})}
                    required
                  >
                    <option value="平日上午">平日上午 </option>
                    <option value="平日下午">平日下午 </option>
                    <option value="周末上午">周末上午 </option>
                    <option value="周末下午">周末下午 </option>
                  </select>
                </div>
              </div>

              <div className="m-1">
                <div className="input-group">
                  <div className="input-group-prepend">
                    <span
                      className="input-group-text alert-primary"
                      id="validationTooltipUsernamePrepend"
                    >
                      介紹人
                    </span>
                  </div>
                  <input
                    type="text-area"
                    id="introPeople"
                    className="form-control"
                    placeholder="介紹人姓名"
                    {...register("introPeople")}
                  />
                </div>
              </div>

              <div className="m-1">
                <div className="input-group">
                  <div className="input-group-prepend">
                    <span
                      className="input-group-text alert-primary"
                      id="validationTooltipUsernamePrepend"
                    >
                      備註事項
                    </span>
                  </div>
                  <input
                    type="text-area"
                    id="memo"
                    className="form-control"
                    placeholder=""
                    {...register("memo")}
                  />
                </div>
              </div>
            </div>
          </div>

          <div className="card border-0 mt-2 p-3" style={{ width: "100%" }}>
            <input
              id="b1"
              type ="submit"
              value="報名去"
              className="btn btn-info "
            />
              
           

          </div>
        </form>
      </div>



           
          </div>
        </div>
      </div>

       {/** ----劃位完成使用 */}
       <div className="modal fade" id="remindPopMsgModal" tabindex="-1" role="dialog" aria-labelledby="exampleModalLabel" aria-hidden="true" modal="show">
        <div className="modal-dialog-centered modal-dialog" role="document">
          <div className="modal-content">
            <div className="modal-header">
              <h5 className="modal-title" id="exampleModalLabel">好好游學員APP</h5>
              <button type="button" className="close" data-dismiss="modal" aria-label="Close">
                <span aria-hidden="true">&times;</span>
              </button>
            </div>
            <div className="modal-body">
              {msg}
            </div>
            <div className="modal-footer ">

              <div className="btn alert-info ml-auto mr-auto" data-dismiss="modal" style={{ "width": "100pt" }} onClick={() => {
               window.location.href = "https://good2swim.com";
              //  return
              }}>確定</div>

            </div>
          </div>
        </div>
      </div>
      </>
  );
}