import React, { useEffect } from 'react';
import YouTube from 'react-youtube';

const Youtube=()=>{
    const opts = {
      
        width: '100%',
        playerVars: {
          // https://developers.google.com/youtube/player_parameters
          autoplay: 1,
          controls:0,
          disablekb:0,
     
        },
      };

    //   useEffect(()=>{
    //     document.getElementById('play').addEventListener('click', function(event){
    //         event.preventDefault()
    //     })
    //   },[])

      return (
          <>
    {/* <div style={{"top":"100px","width":"100%","height":"80%","position":"absolute",  "zIndex":"10"}}></div> */}
          <div id="play" className="container alert-white" align="center"  style={{"top":"100px","position":"absolute",  "zIndex":"5","color":"black"}}>
            <YouTube  videoId="lu_BJKxqGnk" opts={opts}    className="p-1" />
        </div>
     
      </>
      )
}

export default Youtube;