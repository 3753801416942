
import React, { useState, useEffect } from "react";
import { Route } from "react-router-dom";
import logo from "../icon/good2swim-logo.png";
import bgPic from "../icon/EW2A1883.jpg";
import getUrl from '../function/getUrl.js'
import getDay from "../function/getDay"

// const url=getUrl()
const $ = window.$;
const url="https://golang-server-oi3as7ovga-de.a.run.app"
const today=getDay(0,"-")
//trial 體驗報名
const Trial = (props) => {


  



  return (
    <>
      <div className="container vw-75 rounded" style={{ maxWidth: "700px" }}>
        <div
          className="card   p-1   border-0"
          style={{ backgroundColor: "#75B8A9" }}
        >
          <img src={bgPic} style={{ width: "100%" }} className="align-middle rounded" />
          <div className="card-body p-1" style={{ backgroundColor: "#D8EFDC" }}>
            <div className="col-sm align-middle text-center  rounded">
              <img src={logo} style={{ width: "50px" }} />
              <h5 className="card-title text-info">好好游 體驗報名專區</h5>
            </div>

            <div className="container vw-100 rounded " style={{maxWidth:"700px"}}>
      <div className="card border-0">
        <div class="card-header alert-warning text-center">寶貝體驗報名完成</div>
        <div class="card-body">
          <p class="card-text text-muted text-left">
           提醒您～為加速我們安排寶貝的體驗時段，體驗報名完成，別忘了 Line@ 私訊好好游小編哦！
            <br />
          
          </p>

          <div
            className="form-group card border-0 p-2  "
            style={{ width: "100%" }}
          >
            <div
              className="btn btn-danger p-1"
              onClick={()=>{
                window.location.href="https://www.good2swim.com"
              }}
              style={{ width: "100%" }}
            >
              {" "}
              完成
            </div>
          </div>
        </div>
      </div>
      </div>
      </div>
      </div>
      </div>
    </>
    

  );
};

export default Trial;
