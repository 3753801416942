import React, { useState, useEffect } from "react";
import { Link, useParams } from "react-router-dom";
import logo from "../icon/good2swim-logo.png";
import covid19 from "../icon/covid19.png";



const Covid=()=>{
return <>
 <div className="card m-3" >
  <img src={covid19} className="card-img-top" alt="..."/>
  <div className="card-body p-2 ">
    <h5 className="card-title text-center text-info">我們用心，寶貝安心</h5>
    <p className="card-text text-center text-muted">每日次氯酸加強清潔、閉館紫外線消毒</p>
   
  </div>
</div>
</>

}

export default Covid;