
import React, { useState, useEffect } from 'react';
import checkLogin from "../function/checkLogin.js"
import getDay from "../function/getDay"
import getUrl from '../function/getUrl'
import logo from "../icon/good2swim-logo.png";
import { Link, useParams } from "react-router-dom";
const today=getDay(0,"-")
const url = getUrl()
const PickupStudent = () => {
    const param = useParams();
    const togo = param.togo;
    const pickup=(studentDBID)=>{
   
        fetch(`${url}/singleStudentView?studentDBID=${studentDBID}`).then(res=>res.json()).then(json=>{
           
            sessionStorage.setItem("student",JSON.stringify(json))
        }).then(()=>{
           if (togo=="GetDailyTable"){
            window.location.href=`/${togo}/${today}`
           }else{
            window.location.href=`/${togo}`
           }
            
        })
      
       
    }

    let studentData = JSON.parse(sessionStorage.getItem("studentData"))
    console.log('studentData.lengh',studentData.length)
    if (studentData.length ==1){
       pickup(studentData[0].studentDBID)
        return null
    }

    return (
        <>
            
               
                    <div style={{ textAlign: 'center' }}>
                        <img src={logo} alt="logo" style={{ weight: "120px", height: "120px" }} />
                    </div>

                    <div className="card m-2" >
                    <div className="card-body alert-info   p-2 text-center">請挑選學員</div>
                            <div className="card-body alert-light">
                                {
                                    studentData.map((item,index)=>{
                                        return (
                                            <div  className="btn m-1 alert-success  p-2" style={{width:"100%"}} onClick={()=>{pickup(item.studentDBID)}}><h6>{item.studentDBID} {item.name}</h6></div>
                                        )
                                    })
                                }
                               
                            </div>
                    </div>




               


            

        </>
    );
}




export default PickupStudent;