
import React, { useState, useEffect } from "react";
import { Route } from "react-router-dom";
import logo from './logo.svg';
import Trial from "./pages/trial0502.js";
import Trial2 from "./pages/trial2.js";
import trialDone from "./pages/trialDone.js";
import HeaderBar from "./pages/headerBar.js";
import Navbar from "./pages/navbar.js";
import login from "./pages/login.js";
import loginNormal from "./pages/loginNormal.js";
import loginWithLineID from "./pages/loginWithLineID.js";
import home from "./pages/home.js";
import myRecord from "./pages/myRecord.js";
import makeClass from "./pages/makeClass.js";
import pickupStudent from "./pages/pickupStudent"
import GetDailyTable from "./pages/getDailyTable"
import loginCheckLine from "./pages/loginCheckLine"
import covid from "./pages/covid"
import './App.css';
import Youtube from "./pages/youtube"



//更新後 自動 deploy To Gcp
// gcloud builds submit --tag asia.gcr.io/good2swim-os3/member
//gcloud builds submit --tag asia.gcr.io/good2swim-os3/memberbeta
let env = process.env.NODE_ENV;

function App() {
  return (
    <>
    <div className="p-1 container">
       <div id="headerBar" className="fixed-top">
        <HeaderBar />
      </div>
      <Route path="/covid" exact component={covid} />
      <Route path="/" exact component={loginCheckLine} />
      <Route path="/trial" exact component={Trial2} />
      <Route path="/trialDone" exact component={trialDone} />
      <Route path="/myRecord" exact component={myRecord} />
      <Route path="/makeClass" exact component={makeClass} />
      <Route path="/login" exact component={login} />
      <Route path="/loginNormal" exact component={loginNormal} />
      <Route path="/loginWithLineID" exact component={loginWithLineID} />
      <Route path="/home" exact component={home} />
      <Route path="/GetDailyTable/:getDate" exact component={GetDailyTable}/>
      <Route path="/pickupStudent/:togo" exact component={pickupStudent} />
      <Route path="/youtube" exact component={Youtube} />
    </div>
      <div id="footerBar" className="fixed-bottom">
      <Navbar env={env} />
    </div>
    </>
  );
}

export default App;
