import liff from '@line/liff';
import getUrl from "../function/getUrl.js";
const url = getUrl();


function good2swimLogin(phone,password) {
    fetch(`${url}/login?phone=${phone}&password=${password}`, {
        mode: 'cors',
    }).then(res => {
        return res.json();
    }).then(json => {


        if (json.length > 0) {
            sessionStorage.setItem('studentData', JSON.stringify(json));

            window.location.href = "/pickupStudent/home"

        } 

    })
}

export default () => {
    liff.init({ liffId: '1655965506-jAvBNGVM' }).then(() => {
        console.log(liff.isInClient())
        console.log(liff.isLoggedIn())
        console.log(liff.getOS())
        // console.log(liff.getContext())
        //如果在LineAPP中 啟用登入
        if (liff.isInClient()) {
            // alert('in app')
            // alert(liff.isLoggedIn())
            if (liff.isLoggedIn()) {
                //如果已登入 取得UserID
                /*
                {
                    "userId":"U4af4980629...",
                    "displayName":"Brown",
                    "pictureUrl":"https://profile.line-scdn.net/abcdefghijklmn",
                    "statusMessage":"Hello, LINE!"
        }
                */

                liff.getProfile()
                    .then(profile => {
                        const lineID = profile.userId //處理 LineID
                        // alert(lineID)
                        fetch(`${url}/appGet/checkLineID?lineID=${lineID}`).then(res=>res.json()).then(json=>{
                            alert(json[0]["phone"])
                            if (json[0]["phone"]){
                                //如果有手機有找到 則直接登入
                                let phone=json[0]["phone"]
                                let password=json[0]["password"]
                                good2swimLogin(phone,password)
                            }else{
                                
                                //如果沒有則用轉入 LoginWithLineID
                                window.location.href=`/loginWithLineID/${lineID}`
                            }
                            return 
                        })
                        
                    })
                    .catch((err) => {
                        console.log('error', err);
                    });
                let profile = liff.getProfile().then(profile=>profile)
                let msg = `hello ${profile.displayName} ${profile.userId}`
                // alert(msg)
                // console.log(liff.getProfile())
                //處理登入作業 -》
                /*
                （1) 與server 確認本 LineUserID是否存在 , 如果已連結, 則直接登入系統
                (2) 如果LineUserID不存在，保留LineUserID ，加入登入畫面送出 LineUserID 一併儲存
                */
                return profile
            }
        } else {
            console.log('here')

            return
            // window.location.href="/loginNormal"

        }

        // liff.login()
        // console.log(liff.getProfile())
    });

}

