
import React, { useState, useEffect } from 'react';
import checkLogin from "../function/checkLogin.js"
import getDay from "../function/getDay"
import getUrl from '../function/getUrl'
import logo from "../icon/good2swim-logo.png";


const url = getUrl()
const Login = () => {
    const [msg, setMsg] = useState(null)
    sessionStorage.clear();
    const [phone, setPhone] = useState('');
    const [password, setPassword] = useState('');
    const [error, setError] = useState('')

    function haneleAccountChange(e) {
        setPhone(e.target.value)

    };

    function handlePassword(e) {
        setPassword(e.target.value)

    };


    function good2swimLogin(e) {
        fetch(`${url}/login?phone=${phone}&password=${password}`, {
            mode: 'cors',
        }).then(res => {
            return res.json();
        }).then(json => {


            if (json.length > 0) {
                sessionStorage.setItem('studentData', JSON.stringify(json));

                window.location.href = "/pickupStudent/home"

            } else {
                setMsg('帳號或密碼錯誤')
            }

        })
    }



    return (
        <>
            <div className="container  " style={{maxWidth:"520px"}}>
                <div role="alert" color="warning">
                    <div style={{ textAlign: 'center' }}>
                        <img src={logo} alt="logo" style={{ weight: "120px", height: "120px" }} />
                    </div>




                    <div className="card-body border-0">

                        <form>
                            <div className="card mb-2 ">
                                <div className="card-body alert-info  border-1 p-2 text-center">歡迎回來<br />請輸入您的手機與密碼</div>
                                <div className="card-body border-1" style={{ width: "100%" }}>

                                    <div className="formGroup">
                                        <label>手機
                            <input type="text" name="phone" id="phone" className="form-control" style={{ width: "100%" }} value={phone} onChange={haneleAccountChange} />
                                            <small id="emailHelp" className="form-text text-muted">您的手機即為帳號</small>
                                        </label>
                                    </div>
                                    <div className="formGroup">
                                        <label >密碼
                            <input type="password" name="password" value={password} style={{ width: "100%" }} id="examplePassword" className="form-control" onChange={handlePassword} />
                            <small id="emailHelp" className="form-text text-muted">預設為出生日期 月日共4碼</small>
                                        </label>
                                    </div>

                                    <br />
                                    <div className="formGroup ">
                                        <div className="btn btn-info"  style={{ width: "100%" }} onClick={good2swimLogin} > 登入</div>

                                    </div>
                                    {msg}
                                </div>
                            </div>


                        </form>
                    </div>
                </div>

                <div className="formGroup text-center"><h5>{error}</h5></div>

            </div>

        </>
    );
}




export default Login;