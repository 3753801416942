
//確認是否有設定現在在storage中的學員
function checkStudent(){
    const student=sessionStorage.getItem("student")
    
    //console.log("timecode",timecode)
    if (student==null){
        //時間過期
        //sessionStorage.clear() //清空資料
        window.location.href="/pickupStudent/home"
    }
    


}

export default checkStudent;